import * as Dialog from '@radix-ui/react-dialog';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import InfiniteScroll from 'react-infinite-scroll-component';
import { isDeepEqual, isDeepEqual as isEqual } from 'remeda';

import {
  Button,
  IconSpinner,
  LayoutSingleColumn,
  ListingCard,
  MobileBottomNavigation,
  Page,
  PrimaryButton,
} from '../../components';
import { useConfiguration } from '../../context/configurationContext';
import { useIntl } from '../../util/reactIntl';
import FooterContainer from '../FooterContainer/FooterContainer';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import { SearchFilters } from './SearchFilters/SearchFilters';
import SearchHeader from './SearchHeader/SearchHeader';
import { DEFAULT_PRICE_RANGE } from './SearchPage.filters';
import { RESETTABLE_FILTERS_DEFAULTS } from './SearchPage.filters';
import {
  GetListingsWithFiltersRequest,
  useFilterQueryParams,
  useGetSearchPageTitle,
} from './SearchPage.hooks';
import css from './SearchPage.module.css';
import { createSearchResultSchema } from './SearchPage.shared';
import SearchResultsEmptyState from './SearchResultsEmptyState/SearchResultsEmptyState';
import { IconClose } from 'assets/icons';
import { Chip } from 'components/FieldChip/FieldChip';
import { colorOptions } from 'components/FieldChipSelectColorsInput/FieldChipSelectColorsInput';
import { useGetBrands } from 'hooks/api/listings/useGetBrands';
import { useGetListings } from 'hooks/api/listings/useGetListings';
import { useSendEvent } from 'hooks/api/misc/useSendEvent';
import { useGeolocation } from 'hooks/useGeolocation';
import { Breakpoints, useMediaQueries } from 'hooks/useMediaQueries';
import { Listing } from 'models/ListingModels';
import { AnalyticsEvent } from 'util/analytics-events';
import { cn } from 'util/cn';
import {
  conditionOptions,
  departmentOptions,
  productSubtypeOptions,
  productTypeOptions,
  shipsToOptions,
} from 'util/productOptions/productTypeDefinitions';

type SearchPageComponentProps = {
  listings: any[];
  onManageDisableScrolling: () => void;
  pagination: any; // propTypes.pagination;
  scrollingDisabled: boolean;
  searchInProgress: boolean;
  searchListingsError: any;
  searchParams: object;
};

export const SearchPage: React.FC<SearchPageComponentProps> = props => {
  const { scrollingDisabled } = props;
  const { userGeolocation } = useGeolocation();
  const { mutate: sendEvent } = useSendEvent();

  useEffect(() => {
    sendEvent({ event: AnalyticsEvent.VisitSearchPage });
  }, [sendEvent]);

  const config = useConfiguration();

  const intl = useIntl();

  const isViewportMedium = useMediaQueries({ viewport: 'medium' });
  const [isFiltersOpen, setIsFiltersOpen] = useState(() => {
    // useMediaQueries always starts out as false,
    // so we work around this by checking innerWidth here
    if (typeof window !== 'undefined') {
      return window.innerWidth >= Breakpoints.MEDIUM;
    } else {
      return false;
    }
  });

  const [searchParams, setParams] = useFilterQueryParams();
  const [mobileFiltersSnapshot, setMobileFiltersSnapshot] = useState<typeof searchParams>();

  const listingsParams: GetListingsWithFiltersRequest = {
    ...searchParams,
    priceRange: searchParams.priceRange
      ? [Number(searchParams.priceRange[0]), Number(searchParams.priceRange[1])]
      : undefined,
    country: userGeolocation,
  };

  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useGetListings(listingsParams);
  const listings: Listing[] = data?.pages.flatMap(page => page.listings) || [];

  const loadMore = useCallback(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage]);

  const { description, schema } = createSearchResultSchema(
    listings,
    // TODO: Should we use searchParams here?
    searchParams || {},
    intl,
    config
  );

  const searchPageTitle = useGetSearchPageTitle();
  const metaTitle = searchPageTitle && `${searchPageTitle} | The NOLD`;
  const metaDescription =
    searchPageTitle &&
    `Buy authentic ${searchPageTitle.replace(
      ' - ',
      ' '
    )} at The NOLD. Shop timeless classics and rare finds with worldwide delivery. Elevate your style with unique preloved items.`;

  return (
    <Page
      scrollingDisabled={scrollingDisabled}
      description={description}
      title={metaTitle}
      schema={schema}
    >
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="og:title" content={metaTitle} />
        <meta name="twitter:title" content={metaTitle} />
        <meta name="description" content={metaDescription} />
        <meta name="og:description" content={metaDescription} />
        <meta name="twitter:description" content={metaDescription} />
      </Helmet>
      <LayoutSingleColumn
        topbar={
          <TopbarContainer
            currentPage="SearchPage"
            currentSearchParams={searchParams}
            onSearchChange={text => setParams({ text })}
          />
        }
        footer={<MobileBottomNavigation />}
      >
        <main className={css.layoutWrapperMain} role="main">
          <SearchHeader
            className={css.searchHeaderDesktop}
            toggleFilters={() =>
              setIsFiltersOpen(open => {
                const isOpening = !open;
                if (isOpening) {
                  setMobileFiltersSnapshot(searchParams);
                }
                return isOpening;
              })
            }
            isFiltersOpen={isFiltersOpen}
          />
          {!isViewportMedium && (
            <Dialog.Root open={isFiltersOpen} onOpenChange={setIsFiltersOpen}>
              <AnimatePresence>
                {isFiltersOpen && (
                  <Dialog.Portal forceMount>
                    <Dialog.Overlay />
                    <Dialog.Content asChild forceMount>
                      <motion.div
                        key="filters-dialog"
                        className="fixed inset-0 bg-white flex flex-col h-full w-full overflow-hidden"
                        initial={{ opacity: 0, y: 16 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 16 }}
                        transition={{ duration: 0.15, ease: 'easeInOut' }}
                      >
                        <Dialog.Title className="relative font-syne uppercase font-bold text-2 p-2 flex justify-between items-center">
                          <span>Filters</span>
                          <Dialog.Close
                            className="p-2 absolute right-0 top-0 bottom-0 my-auto"
                            onClick={() => {
                              setParams(mobileFiltersSnapshot || {});
                            }}
                          >
                            <IconClose />
                          </Dialog.Close>
                        </Dialog.Title>
                        <SearchFilters className="h-full content-start flex-1 overflow-y-auto px-2 pb-2" />
                        <AnimatePresence>
                          {!isDeepEqual(searchParams, mobileFiltersSnapshot) && (
                            <motion.div
                              key="apply-filters-button"
                              className="grid gap-2 p-2"
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              exit={{ opacity: 0 }}
                              transition={{ duration: 0.15, ease: 'easeInOut' }}
                            >
                              <PrimaryButton onClick={() => setIsFiltersOpen(false)}>
                                Apply Filters
                              </PrimaryButton>
                              <Dialog.Close
                                asChild
                                onClick={() => setParams(mobileFiltersSnapshot || {})}
                              >
                                <Button>Clear Changes</Button>
                              </Dialog.Close>
                            </motion.div>
                          )}
                        </AnimatePresence>
                      </motion.div>
                    </Dialog.Content>
                  </Dialog.Portal>
                )}
              </AnimatePresence>
            </Dialog.Root>
          )}
          <motion.aside
            initial={false}
            animate={{
              width: isFiltersOpen ? 'clamp(240px, 25vw, 400px)' : '0px',
            }}
            className={css.sidebar}
            data-open={Boolean(isFiltersOpen)}
            data-testid="filterColumnAside"
          >
            <SearchFilters className={css.sidebarContent} />
          </motion.aside>
          <div className={css.listingsContent}>
            <FilterChips />
            {!listings.length && !isLoading && (
              <SearchResultsEmptyState onCtaClick={() => setParams(RESETTABLE_FILTERS_DEFAULTS)} />
            )}
            <InfiniteScroll
              dataLength={listings.length}
              next={loadMore}
              hasMore={!!hasNextPage}
              loader={null}
              scrollThreshold={0.8}
            >
              <div className={css.listings}>
                {listings.map(l => (
                  <ListingCard key={l.id} listing={l} addToFavoritesShown appendSizeToTitle />
                ))}
              </div>
            </InfiniteScroll>
            {(isLoading || isFetchingNextPage) && (
              <div className="grid place-content-center py-5">
                <IconSpinner />
              </div>
            )}
          </div>
        </main>
        <FooterContainer />
      </LayoutSingleColumn>
    </Page>
  );
};

const FilterChips: React.FC = () => {
  const [searchParams, setParams] = useFilterQueryParams();

  const anyParamSet = Boolean(
    searchParams?.department ||
      searchParams?.productType?.length ||
      searchParams?.productSubtype?.length ||
      searchParams?.condition ||
      searchParams?.colors?.length ||
      searchParams?.shipsTo ||
      searchParams?.brandIds?.length ||
      (searchParams?.priceRange && !isEqual(searchParams?.priceRange, DEFAULT_PRICE_RANGE)) ||
      searchParams?.text ||
      searchParams?.sizes?.length
  );

  const { data: brands = [] } = useGetBrands();

  if (!anyParamSet) {
    return null;
  }

  return (
    <div className={css.filterChipsContainer}>
      {searchParams?.text && (
        <Chip onClose={() => setParams({ text: undefined })}>
          Searching for "{searchParams.text}"
        </Chip>
      )}
      {searchParams?.brandIds?.map(brandId => (
        <Chip
          key={brandId}
          onClose={() =>
            setParams({
              brandIds: undefinedIfEmpty(
                arrayify(searchParams.brandIds).filter(bid => bid !== brandId)
              ),
            })
          }
        >
          {brands.find(b => b.id === brandId)?.name}
        </Chip>
      ))}
      {searchParams?.sizes?.map(size => (
        <Chip
          key={size}
          onClose={() =>
            setParams({
              sizes: undefinedIfEmpty(arrayify(searchParams.sizes).filter(s => s !== size)),
            })
          }
        >
          {size}
        </Chip>
      ))}
      {searchParams?.department && (
        <Chip onClose={() => setParams({ department: undefined })}>
          {departmentOptions.find(d => d.value === searchParams?.department)?.label}
        </Chip>
      )}
      {arrayify(searchParams?.productType).map(productType => (
        <Chip
          key={productType}
          onClose={() =>
            setParams({
              productType: undefinedIfEmpty(
                arrayify(searchParams.productType).filter(pt => pt !== productType)
              ),
            })
          }
        >
          {productTypeOptions.find(pt => pt.value === productType)?.label}
        </Chip>
      ))}
      {arrayify(searchParams?.productSubtype).map(productSubtype => (
        <Chip
          key={productSubtype}
          onClose={() =>
            setParams({
              productSubtype: undefinedIfEmpty(
                arrayify(searchParams.productSubtype).filter(pt => pt !== productSubtype)
              ),
            })
          }
        >
          {productSubtypeOptions.find(pt => pt.value === productSubtype)?.label}
        </Chip>
      ))}
      {searchParams?.condition && (
        <Chip onClose={() => setParams({ condition: undefined })}>
          {conditionOptions.find(d => d.value === searchParams?.condition)?.label}
        </Chip>
      )}
      {searchParams?.shipsTo && (
        <Chip onClose={() => setParams({ shipsTo: undefined })}>
          {shipsToOptions.find(d => d.value === searchParams?.shipsTo)?.label}
        </Chip>
      )}
      {arrayify(searchParams?.colors).map(color => (
        <Chip
          key={color}
          onClose={() =>
            setParams({
              colors: undefinedIfEmpty(arrayify(searchParams.colors).filter(c => c !== color)),
            })
          }
        >
          {colorOptions.find(c => c.option === color)?.label}
        </Chip>
      ))}
      {searchParams?.priceRange && !isEqual(searchParams?.priceRange, DEFAULT_PRICE_RANGE) && (
        <Chip onClose={() => setParams({ priceRange: [...DEFAULT_PRICE_RANGE] })}>
          Price - From {searchParams.priceRange[0]} to {searchParams.priceRange[1]}
        </Chip>
      )}
      <Chip
        chipClassName={css.clearAllFiltersButton}
        onClick={() => setParams(RESETTABLE_FILTERS_DEFAULTS)}
      >
        Clear all filters
      </Chip>
    </div>
  );
};

function arrayify<T>(value: T | T[]): T[] {
  if (value === undefined) {
    return [];
  }

  return Array.isArray(value) ? value : [value];
}

function undefinedIfEmpty<T>(value: T[] | undefined): T[] | undefined {
  return value && value.length === 0 ? undefined : value;
}

export default SearchPage;
