import { getPath } from '../../util/routes';
import { MARKETPLACE_ROOT_URL } from 'config/env';
import { Listing } from 'models/ListingModels';
import { createListingSlug } from 'util/createListingSlug';

export const createSearchResultSchema = (listings, mainSearchData, intl, config) => {
  // Schema for search engines (helps them to understand what this page is about)
  // https://schema.org
  // We are using JSON-LD format
  const marketplaceName = config.marketplaceName;
  const { address, keywords } = mainSearchData;
  const keywordsMaybe = keywords ? `"${keywords}"` : null;
  const searchTitle =
    address || keywordsMaybe || intl.formatMessage({ id: 'SearchPage.schemaForSearch' });
  const schemaDescription = intl.formatMessage({ id: 'SearchPage.schemaDescription' });
  const schemaTitle = intl.formatMessage(
    { id: 'SearchPage.schemaTitle' },
    { searchTitle, marketplaceName }
  );

  const schemaListings = listings.map((l: Listing, i) => {
    const title = l.title;
    const pathToItem = getPath('ListingPage', {
      id: l.id,
      slug: createListingSlug(l),
    });
    return {
      '@type': 'ListItem',
      position: i,
      url: `${MARKETPLACE_ROOT_URL}${pathToItem}`,
      name: title,
    };
  });

  const schemaMainEntity = JSON.stringify({
    '@type': 'ItemList',
    name: searchTitle,
    itemListOrder: 'https://schema.org/ItemListOrderAscending',
    itemListElement: schemaListings,
  });
  return {
    title: schemaTitle,
    description: schemaDescription,
    schema: {
      '@context': 'https://schema.org',
      '@type': 'SearchResultsPage',
      description: schemaDescription,
      name: schemaTitle,
      mainEntity: [schemaMainEntity],
    },
  };
};
